import { type FC } from "react";
import {
  type Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";

import { useDashboardWebhooksDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Clipboard } from "@styled/clipboard";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Well } from "@styled/well";

import { ConnectionBasicLink } from "./connection_basic_link";
import { TriggerArgumentsList } from "./trigger_arguments_list";
import { WebhookDestroyButton } from "./webhook_destroy_button";
import { WorkflowBasicLink } from "./workflow_basic_link";

type State = {
  url: string;
  hint: string;
};

export const DashboardWebhooksDetails: FC = () => {
  const location: Location<State | undefined> = useLocation();
  const { id } = useParams<{ id: string }>();

  if (!id) throw new Error("undefined ID");

  const navigate = useNavigate();

  const { data } = useDashboardWebhooksDetailsQuery({
    variables: { id: id },
  });

  const url = location.state?.url; // a url may be assigned when redirecting via a form
  const hint = location.state?.hint; // a hint may be assigned when redirecting via a form

  const webhook = data?.webhook;

  if (!webhook) return;

  const onDestroy = () => {
    navigate("/dashboard/artifacts");
  };

  return (
    <Page>
      <Headline title={webhook.description}>
        <ModifyLink to={`/dashboard/triggers/webhooks/${webhook.id}/edit`} />
        <WebhookDestroyButton webhook={webhook} onDestroy={onDestroy} />
      </Headline>

      {hint && <Notification color="indigo">{hint}</Notification>}

      {url && (
        <Well actions={<Clipboard text={`curl -X POST "${url}"`} />}>
          <code>curl -X POST {url}</code>
        </Well>
      )}

      <Attribute name="Description">{webhook.description}</Attribute>

      <Attribute name="Connection">
        {webhook.connection ? (
          <ConnectionBasicLink connection={webhook.connection} />
        ) : (
          "N/A"
        )}
      </Attribute>

      <Attribute name="Workflow">
        <WorkflowBasicLink workflow={webhook.workflow} />
      </Attribute>

      <Attribute name="Parameters">
        <TriggerArgumentsList params={webhook.params} />
      </Attribute>
    </Page>
  );
};
