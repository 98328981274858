import { type FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  type Skill,
  type StepInput,
  useDashboardSkillsListQuery,
} from "@app_schema";

import { Field } from "@styled/field";
import { Label } from "@styled/label";
import { LabelCheckbox } from "@styled/label_checkbox";
import { LabelCheckboxDescription } from "@styled/label_checkbox_description";
import { LabelCheckboxName } from "@styled/label_checkbox_name";

const ToolCheckbox: FC<{
  name?: string;
  skill: Pick<Skill, "id" | "name" | "description">;
  checked?: boolean;
  onChange(checked?: boolean): void;
}> = ({ name, skill, checked, onChange }) => (
  <LabelCheckbox
    name={name}
    value={skill.id}
    checked={checked}
    onChange={(event) => onChange(event.target.checked)}
  >
    <div className="flex flex-col">
      <LabelCheckboxName>{skill.name}</LabelCheckboxName>
      <LabelCheckboxDescription>{skill.description}</LabelCheckboxDescription>
    </div>
  </LabelCheckbox>
);

export const DashboardStepsFormActionPromptToolsFieldset: FC<{
  name?: `action.prompt.tools`;
}> = ({ name = "action.prompt.tools" }) => {
  const { control } = useFormContext<StepInput>();
  const { data } = useDashboardSkillsListQuery();
  const skills = data?.skills;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      render={({ field: { value, onChange } }) => {
        const selections = new Set(value?.map(({ skillID }) => skillID) ?? []);

        const onInsert = (skill: Pick<Skill, "id">) => {
          onChange([...(value ?? []), { skillID: skill.id }]);
        };

        const onDelete = (skill: Pick<Skill, "id">) => {
          onChange(value?.filter(({ skillID }) => skillID !== skill.id) ?? []);
        };

        return (
          <Field>
            <Label>Tools:</Label>
            <div className="flex max-h-80 flex-col gap-2 overflow-y-auto">
              {skills?.map((skill) => (
                <ToolCheckbox
                  key={skill.id}
                  name={name}
                  skill={skill}
                  checked={selections.has(skill.id)}
                  onChange={(checked) => {
                    if (checked) onInsert(skill);
                    else onDelete(skill);
                  }}
                />
              ))}
            </div>
          </Field>
        );
      }}
    />
  );
};
