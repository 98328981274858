import { LoaderFunctionArgs } from "react-router";

import {
  DashboardArtifactsDetailsDocument,
  type DashboardArtifactsDetailsQuery,
  type DashboardArtifactsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_ARTIFACTS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardArtifactsDetailsQuery,
    DashboardArtifactsDetailsQueryVariables
  >({
    query: DashboardArtifactsDetailsDocument,
    variables: { id: props.params.id! },
    fetchPolicy: "network-only",
  });

  return data;
};
