import {
  DashboardSecretsFieldDocument,
  type DashboardSecretsFieldQuery,
  type DashboardSecretsFieldQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_SECRETS_FIELD_LOADER = async () => {
  const { data } = await client.query<
    DashboardSecretsFieldQuery,
    DashboardSecretsFieldQueryVariables
  >({
    query: DashboardSecretsFieldDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
