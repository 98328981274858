import {
  DashboardArtifactsFieldDocument,
  type DashboardArtifactsFieldQuery,
  type DashboardArtifactsFieldQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_ARTIFACTS_FIELD_LOADER = async () => {
  const { data } = await client.query<
    DashboardArtifactsFieldQuery,
    DashboardArtifactsFieldQueryVariables
  >({
    query: DashboardArtifactsFieldDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
