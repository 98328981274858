import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { RunInput, type WorkflowFragment } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { Page } from "@styled/page";

import { ArgumentsFieldset } from "./arguments_fieldset";

export const DashboardRunsForm: FC<{
  workflow: WorkflowFragment;
  loading?: boolean;
  save(_: RunInput): void;
}> = ({ loading, workflow, save }) => {
  const form = useForm<RunInput>();

  const onSubmit = async (input: RunInput) => {
    if (loading) return;
    save(input);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <ArgumentsFieldset parameters={workflow.parameters} />

          <Button type="submit" loading={loading}>
            Run
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
