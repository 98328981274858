import {
  DashboardWorkflowsFieldDocument,
  type DashboardWorkflowsFieldQuery,
  type DashboardWorkflowsFieldQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_WORKFLOWS_FIELD_LOADER = async () => {
  const { data } = await client.query<
    DashboardWorkflowsFieldQuery,
    DashboardWorkflowsFieldQueryVariables
  >({
    query: DashboardWorkflowsFieldDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
