import {
  DashboardSkillsFieldDocument,
  type DashboardSkillsFieldQuery,
  type DashboardSkillsFieldQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_SKILLS_FIELD_LOADER = async () => {
  const { data } = await client.query<
    DashboardSkillsFieldQuery,
    DashboardSkillsFieldQueryVariables
  >({
    query: DashboardSkillsFieldDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
