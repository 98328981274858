import { FC } from "react";

import { type Connection } from "@app_schema";

import { BasicLink } from "@styled/basic_link";

export const ConnectionBasicLink: FC<{
  connection: Pick<Connection, "id" | "uid" | "name">;
}> = ({ connection }) => (
  <BasicLink to={`/dashboard/connections/${connection.id}`}>
    {connection.name} (UID: {connection.uid})
  </BasicLink>
);
