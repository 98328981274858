import { type FC } from "react";

import { type Skill } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badges } from "@styled/badges";

import { SkillBadgeLink } from "./skill_badge_link";

export const SkillsAttribute: FC<{
  name?: string;
  skills: Array<Pick<Skill, "id" | "name">>;
}> = ({ skills, name = "Skills" }) => (
  <Attribute name={name}>
    {skills.length > 0 ? (
      <Badges>
        {skills.map((skill) => (
          <SkillBadgeLink key={skill.id} skill={skill} />
        ))}
      </Badges>
    ) : (
      <>None</>
    )}
  </Attribute>
);
