import { type FC } from "react";

import { type Artifact } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badges } from "@styled/badges";

import { ArtifactBadgeLink } from "./artifact_badge_link";

export const ArtifactsAttribute: FC<{
  name?: string;
  artifacts: Array<Pick<Artifact, "id" | "name">>;
}> = ({ artifacts, name = "Artifacts" }) => (
  <Attribute name={name}>
    {artifacts.length > 0 ? (
      <Badges>
        {artifacts.map((artifact) => (
          <ArtifactBadgeLink key={artifact.id} artifact={artifact} />
        ))}
      </Badges>
    ) : (
      <>None</>
    )}
  </Attribute>
);
