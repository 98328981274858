import { type FC } from "react";
import { useNavigate, useParams } from "react-router";

import {
  WebhookInput,
  useDashboardWebhooksDetailsQuery,
  useWebhookChangeMutation,
} from "@app_schema";

import { DashboardWebhooksForm } from "./webhooks_form";

export const DashboardWebhooksEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("undefined ID");

  const navigate = useNavigate();

  const { data: defaults } = useDashboardWebhooksDetailsQuery({
    variables: { id },
  });
  const [execute, { loading, data }] = useWebhookChangeMutation();
  const errors = data?.webhook.change?.errors;

  const save = async (input: WebhookInput) => {
    if (loading) return;
    const result = await execute({ variables: { id, input } });
    const webhook = result.data?.webhook.change?.webhook;
    if (webhook) navigate(`/dashboard/triggers/webhooks/${webhook.id}`);
  };

  if (!defaults) return;

  return (
    <DashboardWebhooksForm
      webhook={defaults.webhook}
      errors={errors}
      loading={loading}
      save={save}
    />
  );
};
