import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { useDashboardSecretsFieldQuery } from "@app_schema";

import { BaseField, type BaseFieldProps } from "@styled/base_field";
import { LabelCheckbox } from "@styled/label_checkbox";
import { LabelCheckboxName } from "@styled/label_checkbox_name";

export const DashboardSecretsField: FC<BaseFieldProps> = ({
  name = "secretIDs",
  label = "Secrets:",
  ...props
}) => {
  const { register } = useFormContext();
  const { data } = useDashboardSecretsFieldQuery();
  const secrets = data?.secrets;

  if (!secrets) return;

  return (
    <BaseField name={name} label={label} {...props}>
      {() => (
        <div className="flex flex-col gap-2">
          {secrets.map((secret) => (
            <LabelCheckbox {...register(name)} value={secret.id}>
              <LabelCheckboxName>{secret.key}</LabelCheckboxName>
            </LabelCheckbox>
          ))}
        </div>
      )}
    </BaseField>
  );
};
