import { type FC, forwardRef } from "react";
import { useFormContext } from "react-hook-form";

import { type Artifact, useDashboardArtifactsFieldQuery } from "@app_schema";

import { BaseField, type BaseFieldProps } from "@styled/base_field";
import { LabelCheckbox } from "@styled/label_checkbox";
import { LabelCheckboxDescription } from "@styled/label_checkbox_description";
import { LabelCheckboxName } from "@styled/label_checkbox_name";

export const ArtifactLabelCheckbox = forwardRef<
  HTMLInputElement,
  {
    artifact: Pick<Artifact, "id" | "name" | "description">;
  }
>(({ artifact, ...props }, ref) => (
  <LabelCheckbox ref={ref} value={artifact.id} {...props}>
    <LabelCheckboxName>{artifact.name}</LabelCheckboxName>
    <LabelCheckboxDescription>{artifact.description}</LabelCheckboxDescription>
  </LabelCheckbox>
));

export const DashboardArtifactsField: FC<BaseFieldProps> = ({
  name = "artifact_ids",
  label = "Artifacts:",
  ...props
}) => {
  const { register } = useFormContext();
  const { data } = useDashboardArtifactsFieldQuery();
  const artifacts = data?.artifacts.filter(({ deleted }) => !deleted);

  return (
    <BaseField name={name} label={label} {...props}>
      {() => (
        <div className="flex max-h-64 flex-col gap-2 overflow-y-auto">
          {artifacts?.map((artifact) => (
            <ArtifactLabelCheckbox
              key={artifact.id}
              artifact={artifact}
              {...register(name)}
            />
          ))}
        </div>
      )}
    </BaseField>
  );
};
