import { type FC } from "react";

import { type Secret } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badges } from "@styled/badges";

import { SecretBadgeLink } from "./secret_badge_link";

export const SecretsAttribute: FC<{
  name?: string;
  secrets: Array<Pick<Secret, "id" | "key">>;
}> = ({ secrets, name = "Secrets" }) => (
  <Attribute name={name}>
    {secrets.length > 0 ? (
      <Badges>
        {secrets.map((secret) => (
          <SecretBadgeLink key={secret.id} secret={secret} />
        ))}
      </Badges>
    ) : (
      <>None</>
    )}
  </Attribute>
);
