import { type FC } from "react";

import { type WebhookFragment } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Attributes } from "@styled/attributes";

import { ConnectionBasicLink } from "./connection_basic_link";
import { WorkflowBasicLink } from "./workflow_basic_link";

export const WebhooksListAttributes: FC<{
  webhook: WebhookFragment;
}> = ({ webhook }) => (
  <Attributes>
    <Attribute name="Connection">
      {webhook.connection ? (
        <ConnectionBasicLink connection={webhook.connection} />
      ) : (
        "N/A"
      )}
    </Attribute>
    <Attribute name="Workflow">
      <WorkflowBasicLink workflow={webhook.workflow} />
    </Attribute>
  </Attributes>
);
