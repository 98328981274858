import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLDivElement;
type P = HTMLAttributes<T>;

export const Skeleton = forwardRef<T, P>(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx("animate-pulse", className)} {...props} />
));
