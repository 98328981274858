import { type FC } from "react";
import { useNavigate, useParams } from "react-router";

import { useDashboardSchedulesDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Page } from "@styled/page";

import { ScheduleDestroyButton } from "./schedule_destroy_button";
import { TriggerArgumentsList } from "./trigger_arguments_list";
import { WorkflowBasicLink } from "./workflow_basic_link";

export const DashboardSchedulesDetails: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) throw new Error("undefined ID");

  const navigate = useNavigate();

  const { data } = useDashboardSchedulesDetailsQuery({
    variables: { id: id },
  });

  const schedule = data?.schedule;

  if (!schedule) return;

  const onDestroy = () => {
    navigate("/dashboard/triggers/schedules");
  };

  return (
    <Page>
      <Headline title={schedule.description}>
        <ModifyLink to={`/dashboard/triggers/schedules/${schedule.id}/edit`} />
        <ScheduleDestroyButton schedule={schedule} onDestroy={onDestroy} />
      </Headline>

      <Attribute name="Description">{schedule.description}</Attribute>

      <Attribute name="Frequency">{schedule.frequency}</Attribute>

      <Attribute name="Workflow">
        <WorkflowBasicLink workflow={schedule.workflow} />
      </Attribute>

      <Attribute name="Parameters">
        <TriggerArgumentsList params={schedule.params} />
      </Attribute>
    </Page>
  );
};
