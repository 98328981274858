import { type SelectHTMLAttributes, forwardRef } from "react";

import { useDashboardWorkflowsFieldQuery } from "@app_schema";

import { type BaseFieldProps } from "@styled/base_field";
import { SelectField } from "@styled/select_field";

type T = HTMLSelectElement;
type P = BaseFieldProps & SelectHTMLAttributes<T>;

export const WorkflowSelectField = forwardRef<T, P>(
  ({ disabled, value, ...props }, ref) => {
    const { data, loading } = useDashboardWorkflowsFieldQuery();
    const workflows = data?.workflows.filter((workflow) => !workflow.deleted);

    return (
      <SelectField
        {...props}
        value={value}
        disabled={disabled ?? loading}
        ref={ref}
      >
        <option value="">- Workflow -</option>

        {workflows?.map((workflow) => (
          <option key={workflow.id} value={workflow.id}>
            {workflow.name}
          </option>
        ))}
      </SelectField>
    );
  },
);
