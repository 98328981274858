import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { useDashboardWorkflowParametersQuery } from "@app_schema";

import { ArgumentsFieldset } from "./arguments_fieldset";

export const TriggerArgumentsFieldset: FC = () => {
  const form = useFormContext();
  const workflowID = form.watch("workflowID");

  const { data } = useDashboardWorkflowParametersQuery({
    variables: { workflowID: workflowID },
    skip: !workflowID,
  });

  const parameters = data?.workflow?.parameters;
  if (!parameters) return;

  return <ArgumentsFieldset parameters={parameters} />;
};
