import type { FC, ReactNode } from "react";

import { clsx } from "clsx";

import { RichTextAreaBar } from "./rich_text_area_bar";

export const RichTextAreaHeader: FC<{
  children: ReactNode;
  className?: string;
}> = ({ className, ...props }) => (
  <RichTextAreaBar
    {...props}
    className={clsx("rounded-t border-t border-r border-l", className)}
  />
);
