import { type FC } from "react";

import { type Artifact, useArtifactDestoryMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const ArtifactDestroyButton: FC<{
  artifact: Pick<Artifact, "id" | "name">;
  onDestroy?(): void;
}> = ({ artifact, onDestroy }) => {
  const [execute, { loading }] = useArtifactDestoryMutation();

  const destroy = async () => {
    await execute({ variables: { id: artifact.id } });
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={<>Are you sure you want to delete "{artifact.name}"?</>}
    />
  );
};
