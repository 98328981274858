import { type FC } from "react";
import { useNavigate } from "react-router";

import { type ArtifactInput, useArtifactBuildMutation } from "@app_schema";

import { DashboardArtifactsForm } from "./artifacts_form";

export const DashboardAtifactsNew: FC = () => {
  const navigate = useNavigate();
  const [execute, { loading, data }] = useArtifactBuildMutation();
  const errors = data?.artifacts.build.errors;

  const save = async (input: ArtifactInput) => {
    const result = await execute({ variables: { input } });
    const artifact = result.data?.artifacts.build.artifact;
    if (artifact) navigate(`/dashboard/artifacts/${artifact.id}`);
  };

  return (
    <DashboardArtifactsForm save={save} errors={errors} loading={loading} />
  );
};
