import type { FC } from "react";
import { useFormContext } from "react-hook-form";

import { type ParameterFragment, ParameterKindEnum } from "@app_schema";

import { CheckboxField } from "@styled/checkbox_field";
import { InputField } from "@styled/input_field";
import { TextAreaField } from "@styled/text_area_field";

import { ArtifactSelectField } from "./artifact_select_field";
import { ConnectionSelectField } from "./connection_select_field";
import { VaultSelectField } from "./vault_select_field";

export const ArgumentValueField: FC<{
  name: `params.${string}`;
  parameter: ParameterFragment;
}> = ({ name, parameter }) => {
  const { watch, register } = useFormContext();
  const label = `${parameter.name}:`;
  const field = register(name);

  switch (parameter.kind) {
    case ParameterKindEnum.Artifact:
      return (
        <ArtifactSelectField
          required
          {...field}
          placeholder={!watch(name)}
          label={label}
        />
      );
    case ParameterKindEnum.Connection:
      return (
        <ConnectionSelectField
          required
          {...field}
          placeholder={!watch(name)}
          label={label}
        />
      );
    case ParameterKindEnum.Vault:
      return (
        <VaultSelectField
          required
          {...field}
          placeholder={!watch(name)}
          label={label}
        />
      );
    case ParameterKindEnum.Text:
      return <TextAreaField required {...field} label={label} />;
    case ParameterKindEnum.String:
      return <InputField required {...field} label={label} type="text" />;
    case ParameterKindEnum.Number:
      return <InputField required {...field} label={label} type="number" />;
    case ParameterKindEnum.Boolean:
      return <CheckboxField {...field} label={label} />;
  }
};
