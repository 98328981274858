import { type FC } from "react";

import { faCube } from "@fortawesome/pro-duotone-svg-icons/faCube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type ArtifactFragment } from "@app_schema";

import { ButtonAnchor } from "@styled/button_anchor";

export const ArtifactDownloadLink: FC<{ artifact: ArtifactFragment }> = ({
  artifact,
}) => {
  const url = artifact.url ?? artifact.file?.url;
  if (!url) return;

  return (
    <ButtonAnchor href={url} download>
      <FontAwesomeIcon icon={faCube} />
      <span>Download</span>
    </ButtonAnchor>
  );
};
