import { type FC } from "react";

import { type ScheduleFragment } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Attributes } from "@styled/attributes";

import { WorkflowBasicLink } from "./workflow_basic_link";

export const SchedulesListAttributes: FC<{
  schedule: ScheduleFragment;
}> = ({ schedule }) => (
  <Attributes>
    <Attribute name="Frequency">{schedule.frequency}</Attribute>
    <Attribute name="Workflow">
      <WorkflowBasicLink workflow={schedule.workflow} />
    </Attribute>
  </Attributes>
);
