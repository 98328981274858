import { forwardRef } from "react";

import { useDashboardArtifactsFieldQuery } from "@app_schema";

import { SelectField, type SelectFieldProps } from "@styled/select_field";

type ArtifactSelectFieldProps = Omit<SelectFieldProps, "placeholder"> & {
  placeholder?: boolean;
};

export const ArtifactSelectField = forwardRef<
  HTMLSelectElement,
  ArtifactSelectFieldProps
>(({ placeholder, disabled, ...props }, ref) => {
  const { data, loading } = useDashboardArtifactsFieldQuery();
  const artifacts = data?.artifacts.filter(({ deleted }) => !deleted);

  return (
    <SelectField {...props} disabled={disabled ?? loading} ref={ref}>
      <option value="" disabled={!placeholder}>
        - Artifact -
      </option>

      {artifacts?.map((artifact) => (
        <option key={artifact.id} value={artifact.id}>
          {artifact.name}
        </option>
      ))}
    </SelectField>
  );
});
