import { type FC, useState } from "react";

import { useDashboardArtifactsListQuery } from "@app_schema";

import { useDebounce } from "@application/hooks/use_debounce";

import { Fields } from "@styled/fields";
import { Headline } from "@styled/headline";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { NewLink } from "@styled/new_link";
import { Page } from "@styled/page";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { ArtifactConversationLink } from "./artifact_conversation_link";
import { ArtifactDownloadLink } from "./artifact_download_link";
import { ArtifactRunLink } from "./artifact_run_link";
import { ArtifactDestroyButton } from "./artifacts_destroy_button";

export const DashboardArtifactsList: FC = () => {
  const [query, setQuery] = useState<string | undefined>();
  const [mine, setMine] = useState<boolean | undefined>();

  const { data } = useDashboardArtifactsListQuery({
    variables: { query: useDebounce(query), mine },
  });

  const artifacts = data?.artifacts.filter(({ deleted }) => !deleted);

  return (
    <Page>
      <Headline title="Artifacts">
        <NewLink to="/dashboard/artifacts/new">New</NewLink>
      </Headline>

      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />

        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <List>
        {artifacts?.map((artifact) => (
          <ListItem key={artifact.id}>
            <ListItemContent>
              <ListItemText>
                <ListItemTitle>{artifact.name}</ListItemTitle>
                <ListItemSummary>{artifact.description}</ListItemSummary>
                <ListItemSummary>
                  {artifact.run && <ArtifactRunLink artifact={artifact} />}
                  {artifact.conversation && (
                    <ArtifactConversationLink artifact={artifact} />
                  )}
                </ListItemSummary>
              </ListItemText>
              <ListItemControls>
                <ViewLink to={`/dashboard/artifacts/${artifact.id}`} />
                <ArtifactDownloadLink artifact={artifact} />
                <ArtifactDestroyButton artifact={artifact} />
              </ListItemControls>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </Page>
  );
};
