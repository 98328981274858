import { type FC } from "react";

import { ArtifactFragment } from "@app_schema";

import { BasicLink } from "@styled/basic_link";

export const ArtifactRunLink: FC<{
  artifact: ArtifactFragment;
}> = ({ artifact }) =>
  artifact.run ? (
    <BasicLink to={`/dashboard/runs/${artifact.run.id}`}>
      Run #{artifact.run.id} ({artifact.run.workflow.name})
    </BasicLink>
  ) : (
    "N/A"
  );
