import { type FC } from "react";
import { useNavigate } from "react-router";

import { ScheduleInput, useScheduleBuildMutation } from "@app_schema";

import { DashboardSchedulesForm } from "./schedules_form";

export const DashboardSchedulesNew: FC = () => {
  const navigate = useNavigate();
  const [execute, { loading, data }] = useScheduleBuildMutation();
  const errors = data?.schedules.build.errors;

  const save = async (input: ScheduleInput) => {
    const result = await execute({ variables: { input } });
    const schedule = result.data?.schedules.build?.schedule;
    if (schedule) navigate(`/dashboard/triggers/schedules/${schedule.id}`);
  };

  return (
    <DashboardSchedulesForm errors={errors} loading={loading} save={save} />
  );
};
