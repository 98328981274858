import {
  DashboardConnectionsFieldDocument,
  type DashboardConnectionsFieldQuery,
  type DashboardConnectionsFieldQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_CONNECTIONS_FIELD_LOADER = async () => {
  const { data } = await client.query<
    DashboardConnectionsFieldQuery,
    DashboardConnectionsFieldQueryVariables
  >({
    query: DashboardConnectionsFieldDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
