import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { faNote as faDescription } from "@fortawesome/pro-duotone-svg-icons/faNote";

import {
  type MailboxErrors,
  type MailboxFragment,
  type MailboxInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";

import { TriggerArgumentsFieldset } from "./trigger_arguments_fieldset";
import { WorkflowSelectField } from "./workflow_select_field";

export const DashboardMailboxesForm: FC<{
  mailbox?: MailboxFragment;
  errors?: MailboxErrors | null;
  loading?: boolean;
  save(input: MailboxInput): void;
}> = ({ mailbox, errors, loading, save }) => {
  const form = useForm<MailboxInput>({
    defaultValues: {
      description: mailbox?.description,
      workflowID: mailbox?.workflow.id,
      params: mailbox?.params ?? {},
    },
  });

  const onSubmit = async (input: MailboxInput) => {
    if (loading) return;
    save(input);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            {...form.register("description", { required: "required" })}
            id="description"
            label="Description:"
            placeholder="Description"
            icon={faDescription}
            errors={errors}
          />

          <WorkflowSelectField
            {...form.register("workflowID", { required: "required" })}
            id="workflow_id"
            label="Workflow:"
          />

          <TriggerArgumentsFieldset />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
