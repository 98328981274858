import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDashboardConnectionsFieldQuery } from "@app_schema";

import { BaseField, type BaseFieldProps } from "@styled/base_field";
import { LabelCheckbox } from "@styled/label_checkbox";
import { LabelCheckboxDescription } from "@styled/label_checkbox_description";
import { LabelCheckboxName } from "@styled/label_checkbox_name";

import { ConnectionTitle } from "./connection_title";

export const DashboardConnectionsField: FC<BaseFieldProps> = ({
  name = "connectionIDs",
  label = "Connections:",
  ...props
}) => {
  const { register } = useFormContext();
  const { data } = useDashboardConnectionsFieldQuery();
  const connections = data?.connections;

  return (
    <BaseField name={name} label={label} {...props}>
      {() =>
        connections ? (
          <div className="flex flex-col gap-2">
            {connections.map((connection) => (
              <LabelCheckbox {...register(name)} value={connection.id}>
                <div>
                  <LabelCheckboxName>
                    <ConnectionTitle connection={connection} />
                  </LabelCheckboxName>
                  <LabelCheckboxDescription>
                    UID: {connection.uid}
                  </LabelCheckboxDescription>
                </div>
              </LabelCheckbox>
            ))}
          </div>
        ) : (
          <FontAwesomeIcon icon={faSpinner} spin />
        )
      }
    </BaseField>
  );
};
