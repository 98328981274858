import { type LoaderFunctionArgs } from "react-router";

import {
  DashboardSchedulesDetailsDocument,
  type DashboardSchedulesDetailsQuery,
  type DashboardSchedulesDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_SCHEDULES_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardSchedulesDetailsQuery,
    DashboardSchedulesDetailsQueryVariables
  >({
    query: DashboardSchedulesDetailsDocument,
    variables: { id: props.params.id! },
    fetchPolicy: "network-only",
  });

  return data;
};
