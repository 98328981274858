import { type FC } from "react";

import { type ExecutionDetailsFragment } from "@app_schema";

import { Clipboard } from "@styled/clipboard";
import { Modal } from "@styled/modal";
import { ModalClose } from "@styled/modal_close";
import { ModalContent } from "@styled/modal_content";
import { ModalFooter } from "@styled/modal_footer";
import { ModalHeader } from "@styled/modal_header";
import { Well } from "@styled/well";

type Display = "prompt" | "output";

const NAMES = {
  prompt: "Prompt",
  output: "Output",
};

export const ExecutionDetailModal: FC<{
  execution: ExecutionDetailsFragment;
  display: Display;
  onClose: () => void;
}> = ({ execution, display, onClose }) => {
  const text = (() => {
    switch (display) {
      case "prompt":
        return execution.prompt;
      case "output":
        return execution.output;
    }
  })();

  if (!text) return null;

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        {NAMES[display]} on {execution.llm}
        <ModalClose onClose={onClose} />
      </ModalHeader>
      <ModalContent>
        <pre className="max-h-96 overflow-y-auto break-words whitespace-pre-wrap">
          <Well>
            <code>{text}</code>
          </Well>
        </pre>
        <ModalFooter>
          <Clipboard text={text}>
            <div>Copy to Clipboard</div>
          </Clipboard>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
