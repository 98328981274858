import { type FC } from "react";

import { ScheduleFragment, useScheduleDestoryMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const ScheduleDestroyButton: FC<{
  schedule: ScheduleFragment;
  onDestroy?(): void;
}> = ({ schedule, onDestroy }) => {
  const [execute, { loading }] = useScheduleDestoryMutation({
    variables: { id: schedule.id },
  });

  const workflow = schedule.workflow;

  const destroy = async () => {
    await execute();
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      disabled={schedule.deleted}
      destroy={destroy}
      loading={loading}
      confirmation={
        <>
          Are you sure you want to delete the schedule "{schedule.description}"
          on the workflow "{workflow.name}"? Once deleted no future schedules
          will be run. This won't delete the workflow itself or any runs.
        </>
      }
    />
  );
};
