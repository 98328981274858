import { type FC } from "react";
import { useNavigate } from "react-router";

import { type MailboxInput, useMailboxBuildMutation } from "@app_schema";

import { DashboardMailboxesForm } from "./mailboxes_form";

export const DashboardMailboxesNew: FC = () => {
  const navigate = useNavigate();

  const [execute, { loading, data }] = useMailboxBuildMutation();
  const errors = data?.mailboxes.build?.errors;

  const save = async (input: MailboxInput) => {
    if (loading) return;
    const result = await execute({ variables: { input } });
    const mailbox = result.data?.mailboxes.build?.mailbox;

    if (mailbox) {
      navigate(`/dashboard/triggers/mailboxes/${mailbox.id}`, {
        state: {
          hint: "Please save the address associated with your mailbox.",
        },
      });
    }
  };

  return (
    <DashboardMailboxesForm errors={errors} loading={loading} save={save} />
  );
};
