import { type LoaderFunctionArgs } from "react-router-dom";

import { DASHBOARD_STEPS_DETAILS_LOADER } from "./dashboard_steps_details_loader";
import { DASHBOARD_STEPS_FORM_LOADER } from "./dashboard_steps_form_loader";

export const DASHBOARD_STEPS_EDIT_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const loader = DASHBOARD_STEPS_DETAILS_LOADER(props);
  await DASHBOARD_STEPS_FORM_LOADER();
  return await loader;
};
