import { FC } from "react";

import { Attribute } from "@styled/attribute";
import { Group } from "@styled/group";

export const TriggerArgumentsList: FC<{
  params: Record<string, string | number | boolean | null>;
}> = ({ params }) => {
  const names = Object.keys(params);
  if (!names.length) return "N/A";

  return (
    <Group>
      {names.map((name, key) => (
        <Attribute name={name} key={key}>
          {params[name] ?? "N/A"}
        </Attribute>
      ))}
    </Group>
  );
};
