import { type FC } from "react";

import { useDashboardSettingsQuery } from "@app_schema";

import { Headline } from "@styled/headline";
import { Page } from "@styled/page";

import { SettingsAvatarAttachDetachForm } from "./settings_avatar_attach_detach_form";
import { SettingsPasswordChangeForm } from "./settings_password_change_form";
import { SettingsSecretsForm } from "./settings_secrets_form";
import { SettingsUserChangeForm } from "./settings_user_change_form";

export const DashboardSettings: FC = () => {
  const { data } = useDashboardSettingsQuery();
  const me = data?.me;

  return (
    <Page>
      <Headline title="Avatar" />
      {me && <SettingsAvatarAttachDetachForm me={me} />}

      <Headline title="Profile" />
      {me && <SettingsUserChangeForm me={me} />}

      <Headline title="Password" />
      {me && <SettingsPasswordChangeForm />}

      <Headline title="Secrets" />
      <SettingsSecretsForm />
    </Page>
  );
};
