import { type FC } from "react";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDashboardArtifactsDataQuery } from "@app_schema";

import { Prose } from "@styled/prose";

export const DashboardArtifactsData: FC<{
  id: string;
}> = ({ id }) => {
  const { data } = useDashboardArtifactsDataQuery({
    variables: { id },
  });

  if (!data) return <FontAwesomeIcon icon={faSpinner} spin />;
  const html = data.artifact.html;

  if (!html) return null;

  return (
    <Prose
      dangerouslySetInnerHTML={{ __html: html }}
      className="overflow-y-scroll rounded-md border border-dashed border-slate-200 p-8"
    />
  );
};
