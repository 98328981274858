import { type FC } from "react";

import { type ArtifactFragment } from "@app_schema";

import { BasicLink } from "@styled/basic_link";

export const ArtifactConversationLink: FC<{
  artifact: ArtifactFragment;
}> = ({ artifact }) =>
  artifact.conversation ? (
    <BasicLink to={`/dashboard/conversations/${artifact.conversation.id}`}>
      Conversation #{artifact.conversation.id} (
      {artifact.conversation.agent.name})
    </BasicLink>
  ) : (
    "N/A"
  );
