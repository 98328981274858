import { type FC } from "react";
import { useNavigate, useParams } from "react-router";

import {
  type MailboxInput,
  useDashboardMailboxesDetailsQuery,
  useMailboxChangeMutation,
} from "@app_schema";

import { DashboardMailboxesForm } from "./mailboxes_form";

export const DashboardMailboxesEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("undefined ID");

  const navigate = useNavigate();

  const { data: defaults } = useDashboardMailboxesDetailsQuery({
    variables: { id: id },
  });
  const [execute, { loading, data }] = useMailboxChangeMutation();
  const errors = data?.mailbox.change?.errors;

  const save = async (input: MailboxInput) => {
    if (loading) return;
    const result = await execute({ variables: { id, input } });
    const mailbox = result.data?.mailbox.change?.mailbox;

    if (mailbox) navigate(`/dashboard/triggers/mailboxes/${mailbox.id}`);
  };

  if (!defaults) return;

  return (
    <DashboardMailboxesForm
      mailbox={defaults.mailbox}
      errors={errors}
      loading={loading}
      save={save}
    />
  );
};
