import { type FC } from "react";

import { type ParameterFragment } from "@app_schema";

import { Field } from "@styled/field";
import { Fieldset } from "@styled/fieldset";
import { Group } from "@styled/group";
import { Label } from "@styled/label";

import { ArgumentValueField } from "./argument_value_field";

export const ArgumentsFieldset: FC<{
  label?: string;
  parameters: ParameterFragment[];
}> = ({ parameters, label = "Parameters" }) => {
  if (!parameters.length) return;

  return (
    <Field>
      <Label>{label}:</Label>
      <Group>
        <Fieldset>
          {parameters.map((parameter) => (
            <div key={parameter.id}>
              <ArgumentValueField
                name={`params.${parameter.name}`}
                parameter={parameter}
              />
            </div>
          ))}
        </Fieldset>
      </Group>
    </Field>
  );
};
