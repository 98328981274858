import { type FC, useState } from "react";

import { useDashboardWebhooksListQuery } from "@app_schema";

import { Fields } from "@styled/fields";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { Notification } from "@styled/notification";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { RunRecent } from "./run_recent";
import { DashboardTriggersContainer } from "./triggers_container";
import { WebhookDestroyButton } from "./webhook_destroy_button";
import { WebhooksListAttributes } from "./webhooks_list_attributes";

export const DashboardWebhooksList: FC = () => {
  const [mine, setMine] = useState<boolean | undefined>();
  const [query, setQuery] = useState<string | undefined>();
  const { data, loading } = useDashboardWebhooksListQuery({
    variables: { query, mine },
  });
  const webhooks = data?.webhooks.filter(({ deleted }) => !deleted);

  return (
    <DashboardTriggersContainer loading={loading}>
      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />
        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <Notification color="slate">
        Webhooks offer the ability to trigger a workflow run programatically.
        You can easily call a webhook using any programming language via an HTTP
        library or by using curl. Be sure to label your webhooks so you can
        easily identify them in the future.
      </Notification>

      <List>
        {webhooks?.map((webhook) => (
          <ListItem key={webhook.id}>
            <ListItemContent>
              <ListItemText>
                <ListItemTitle>
                  <span>{webhook.description}</span>
                </ListItemTitle>
                <ListItemSummary>
                  <WebhooksListAttributes webhook={webhook} />
                </ListItemSummary>
                {webhook.runs.length > 0 && (
                  <ListItemSummary>
                    <RunRecent runs={webhook.runs} />
                  </ListItemSummary>
                )}
              </ListItemText>

              <ListItemControls>
                <ViewLink to={`/dashboard/triggers/webhooks/${webhook.id}`} />
                <WebhookDestroyButton webhook={webhook} />
              </ListItemControls>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </DashboardTriggersContainer>
  );
};
