import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { faNote as faDescription } from "@fortawesome/pro-duotone-svg-icons/faNote";

import {
  type WebhookErrors,
  type WebhookFragment,
  type WebhookInput,
  Webhook__Kind,
} from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { SelectField } from "@styled/select_field";
import { Sentence } from "@styled/sentence";

import { TriggerArgumentsFieldset } from "./trigger_arguments_fieldset";
import { WebhooksFormCustomFields } from "./webhooks_form_custom_fields";
import { WebhooksFormGithubFields } from "./webhooks_form_github_fields";
import { WebhooksFormIntuitFields } from "./webhooks_form_intuit_fields";
import { WorkflowSelectField } from "./workflow_select_field";

const CHARACTERS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split("");

const random = (length: number): string =>
  Array.from(
    { length },
    () => CHARACTERS[Math.floor(Math.random() * CHARACTERS.length)],
  ).join("");

export const DashboardWebhooksForm: FC<{
  webhook?: WebhookFragment;
  loading?: boolean;
  errors?: WebhookErrors | null;
  save(input: WebhookInput): void;
}> = ({ webhook, loading, errors, save }) => {
  const form = useForm<WebhookInput>({
    defaultValues: {
      description: webhook?.description,
      connectionID: webhook?.connection?.id,
      workflowID: webhook?.workflow.id,
      username: webhook ? undefined : random(32),
      password: webhook ? undefined : random(32),
      kind: webhook?.kind,
      options: webhook?.options,
      params: webhook?.params,
    },
  });

  const onSubmit = async (input: WebhookInput) => {
    if (loading) return;
    save(input);
  };

  const kind = form.watch("kind");

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            {...form.register("description", { required: "required" })}
            id="description"
            label="Description:"
            placeholder="Description"
            icon={faDescription}
            errors={errors}
          />

          <SelectField
            {...form.register("kind", { required: "required" })}
            id="kind"
            label="Kind:"
          >
            <option value="" disabled={!!kind}>
              - Kind -
            </option>
            <option value={Webhook__Kind.Custom}>Custom</option>
            <option value={Webhook__Kind.Github}>Github</option>
            <option value={Webhook__Kind.Intuit}>Intuit</option>
          </SelectField>

          {kind === Webhook__Kind.Custom && (
            <WebhooksFormCustomFields errors={errors} />
          )}
          {kind === Webhook__Kind.Github && (
            <WebhooksFormGithubFields errors={errors} />
          )}
          {kind === Webhook__Kind.Intuit && (
            <WebhooksFormIntuitFields errors={errors} />
          )}

          <WorkflowSelectField
            {...form.register("workflowID", { required: "required" })}
            id="workflow_id"
            label="Workflow:"
          />

          <TriggerArgumentsFieldset />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
