import { type FC } from "react";
import { Link } from "react-router-dom";

import { type Artifact } from "@app_schema";

import { Badge } from "@styled/badge";

export const ArtifactBadgeLink: FC<{
  artifact: Pick<Artifact, "id" | "name">;
}> = ({ artifact }) => (
  <Link to={`/dashboard/artifacts/${artifact.id}`}>
    <Badge>{artifact.name}</Badge>
  </Link>
);
