import { type FC, type FormEvent, useState } from "react";
import { useActiveStorage } from "react-activestorage";

import { faIdCardClip } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import {
  type ArtifactErrors,
  type ArtifactFragment,
  type ArtifactInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Progress } from "@styled/progress";
import { Sentence } from "@styled/sentence";
import { TextAreaField } from "@styled/text_area_field";

export const DashboardArtifactsForm: FC<{
  artifact?: ArtifactFragment | null;
  loading?: boolean;
  errors?: ArtifactErrors | null;
  save(_: ArtifactInput): void;
}> = ({ artifact, loading, errors, save }) => {
  const [name, setName] = useState<string>(artifact?.name ?? "");
  const [description, setDescription] = useState(artifact?.description ?? "");
  const [file, setFile] = useState<File | undefined>();
  const [fileID, setFileID] = useState<string | undefined>();
  const [url, setURL] = useState<string | undefined>(
    artifact?.url ?? undefined,
  );

  const { uploading, progress } = useActiveStorage(file, async ({ blob }) => {
    setFileID(blob?.signed_id);
  });

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!name) return;
    if (file && !fileID) return;

    save({
      name,
      description,
      fileID,
      url,
    });
  };

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        {errors?.base && (
          <Notification color="rose">
            <Sentence>{errors.base}</Sentence>
          </Notification>
        )}

        <InputField
          required
          id="name"
          name="name"
          type="text"
          label="Name:"
          placeholder="Name"
          icon={faIdCardClip}
          value={name}
          errors={errors}
          onChange={(event) => setName(event.target.value)}
        />

        <TextAreaField
          required
          id="description"
          name="description"
          label="Description:"
          placeholder="Description"
          value={description}
          errors={errors}
          onChange={(event) => setDescription(event.target.value)}
        />

        <InputField
          id="file"
          name="file"
          type="file"
          label="File:"
          hint="Optional"
          disabled={uploading}
          onChange={(event) => {
            if (!event.target.files) return;
            setFileID(undefined);
            setFile(event.target.files.item(0) ?? undefined);
          }}
        />

        <InputField
          id="url"
          name="url"
          type="url"
          label="URL:"
          hint="Optional"
          placeholder="e.g. https://workflow.ing/spreadsheet.csv"
          value={url}
          onChange={(event) => {
            setURL(event.target.value || undefined);
          }}
        />

        {progress && <Progress value={progress.loaded / progress.total} />}

        <Button type="submit" loading={loading || uploading}>
          Save
        </Button>
      </Form>
    </Page>
  );
};
