import { type FC, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { faIdBadge } from "@fortawesome/pro-duotone-svg-icons/faIdBadge";
import { faKey } from "@fortawesome/pro-duotone-svg-icons/faKey";
import { faSquareDashedCirclePlus } from "@fortawesome/pro-duotone-svg-icons/faSquareDashedCirclePlus";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type StepErrors, type StepInput } from "@app_schema";

import { Button } from "@styled/button";
import { Field } from "@styled/field";
import { Fields } from "@styled/fields";
import { Fieldset } from "@styled/fieldset";
import { Group } from "@styled/group";
import { InputField } from "@styled/input_field";
import { Label } from "@styled/label";

import { WorkflowSelectField } from "./workflow_select_field";

const DEFAULT_MAPPING = {
  name: "",
  path: "",
};

export const useMessagesFieldArray = (name: `action.branch.mappings`) => {
  const form = useFormContext<StepInput>();
  return useFieldArray({
    keyName: "key",
    control: form.control,
    name,
  });
};

const DashboardStepFormActionBranchMappingFieldset: FC<{
  name: `action.branch.mappings.${number}`;
  onDelete(): void;
}> = ({ name, onDelete }) => {
  const form = useFormContext<StepInput>();
  const destroy = !!form.watch(`${name}._destroy`);

  return (
    <Fields>
      <InputField
        icon={faIdBadge}
        placeholder="Name"
        disabled={destroy}
        {...form.register(`${name}.name`, { required: "required" })}
      />
      <InputField
        icon={faKey}
        placeholder="Path (e.g. a.b.c)"
        disabled={destroy}
        {...form.register(`${name}.path`, { required: "required" })}
      />
      <Button
        disabled={destroy}
        type="button"
        color="rose"
        onClick={() => onDelete()}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </Fields>
  );
};

const DashboardStepsFormActionBranchMappingsFieldset: FC = () => {
  const form = useFormContext<StepInput>();

  const { fields, append, remove, update } = useFieldArray({
    keyName: "key",
    control: form.control,
    name: "action.branch.mappings",
  });

  const onBuild = () => {
    append(DEFAULT_MAPPING);
  };

  const onDelete = (index: number) => {
    const { id, name, path, _destroy } = fields[index];
    if (id) update(index, { id, name, path, _destroy: !_destroy });
    else remove(index);
  };

  return (
    <Field>
      <Label>Parameters:</Label>
      <Group>
        {fields.map(({ key }, index) => (
          <DashboardStepFormActionBranchMappingFieldset
            key={key}
            name={`action.branch.mappings.${index}`}
            onDelete={() => onDelete(index)}
          />
        ))}

        <div>
          <Button type="button" onClick={onBuild}>
            <FontAwesomeIcon icon={faSquareDashedCirclePlus} /> Parameter
          </Button>
        </div>
      </Group>
    </Field>
  );
};

export const DashboardStepsFormActionBranchFieldset: FC<{
  errors?: StepErrors | null;
}> = ({ errors }) => {
  const { register, unregister } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.branch");
    return () => unregister("action.branch");
  }, [register, unregister]);

  return (
    <Fieldset>
      <WorkflowSelectField
        {...register("action.branch.workflowID", { required: "required" })}
        errors={errors ? (errors.messages["action.workflow"] ?? []) : null}
        id="action_branch_workflow_id"
        label="Workflow:"
      />

      <DashboardStepsFormActionBranchMappingsFieldset />
    </Fieldset>
  );
};
