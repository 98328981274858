import { type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDashboardAgentsDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Page } from "@styled/page";

import { AgentConverseButton } from "./agent_converse_button";
import { AgentDestroyButton } from "./agent_destroy_button";
import { AgentName } from "./agent_name";
import { ArtifactsAttribute } from "./artifacts_attribute";
import { ConnectionsAttribute } from "./connections_attribute";
import { SecretsAttribute } from "./secrets_attribute";
import { SkillsAttribute } from "./skills_attribute";
import { VaultsAttribute } from "./vaults_attribute";

export const DashboardAgentsDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) throw new Error("undefined ID");
  const { data } = useDashboardAgentsDetailsQuery({ variables: { id } });
  const agent = data?.agent;

  if (!agent) return;

  return (
    <Page>
      <Headline title={<AgentName agent={agent} />}>
        <AgentConverseButton agent={agent} />
        <ModifyLink to={`/dashboard/agents/${agent.id}/edit`} />
        <AgentDestroyButton
          agent={agent}
          onDestroy={() => navigate("/dashboard/agents")}
        />
      </Headline>

      <Attribute name="Name">{agent.name}</Attribute>
      <Attribute name="Temperature">{agent.temperature}</Attribute>
      <Attribute name="Target">{agent.target}</Attribute>

      <Attribute name="Instructions">
        {agent.instructions ? <pre>{agent.instructions}</pre> : "N/A"}
      </Attribute>

      <ArtifactsAttribute artifacts={agent.artifacts} />
      <ConnectionsAttribute connections={agent.connections} />
      <SecretsAttribute secrets={agent.secrets} />
      <SkillsAttribute skills={agent.skills} />
      <VaultsAttribute vaults={agent.vaults} />
    </Page>
  );
};
