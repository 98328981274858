import { type FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import { useDashboardMailboxesDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { ClipboardButton } from "@styled/clipboard_button";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";

import { MailboxDestroyButton } from "./mailbox_destroy_button";
import { TriggerArgumentsList } from "./trigger_arguments_list";
import { WorkflowBasicLink } from "./workflow_basic_link";

export const DashboardMailboxesDetails: FC = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  if (!id) throw new Error("undefined ID");

  const navigate = useNavigate();

  const hint = location.state?.hint; // a hint may be assigned when redirecting via a form

  const { data } = useDashboardMailboxesDetailsQuery({
    variables: { id: id! },
  });

  const mailbox = data?.mailbox;
  if (!mailbox) return;

  const onDestroy = () => {
    navigate("/dashboard/triggers/mailboxes");
  };

  return (
    <Page>
      <Headline title={mailbox.description}>
        <ModifyLink to={`/dashboard/triggers/mailboxes/${mailbox.id}/edit`} />
        <MailboxDestroyButton mailbox={mailbox} onDestroy={onDestroy} />
      </Headline>
      {hint && (
        <Notification color="indigo">
          Please copy / paste the address associated with your mailbox.
        </Notification>
      )}

      <Attribute name="Address">
        {mailbox.address} <ClipboardButton text={mailbox.address} />
      </Attribute>

      <Attribute name="Description">{mailbox.description}</Attribute>

      <Attribute name="Workflow">
        <WorkflowBasicLink workflow={mailbox.workflow} />
      </Attribute>

      <Attribute name="Parameters">
        <TriggerArgumentsList params={mailbox.params} />
      </Attribute>
    </Page>
  );
};
