import { type FC } from "react";
import { useNavigate } from "react-router";

import { WebhookInput, useWebhookBuildMutation } from "@app_schema";

import { DashboardWebhooksForm } from "./webhooks_form";

export const DashboardWebhooksNew: FC = () => {
  const navigate = useNavigate();

  const [execute, { loading, data }] = useWebhookBuildMutation();
  const errors = data?.webhooks.build?.errors;

  const save = async (input: WebhookInput) => {
    if (loading) return;
    const result = await execute({ variables: { input } });
    const webhook = result.data?.webhooks.build?.webhook;
    if (webhook)
      navigate(`/dashboard/triggers/webhooks/${webhook.id}`, {
        state: {
          url: webhook.url,
          hint: "Please copy / paste the code associated with your webhook. The credentials associated with this webhook will not be available in the future.",
        },
      });
  };

  return (
    <DashboardWebhooksForm errors={errors} loading={loading} save={save} />
  );
};
