import { type FC } from "react";

import { type Connection } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badges } from "@styled/badges";

import { ConnectionBadgeLink } from "./connection_badge_link";

export const ConnectionsAttribute: FC<{
  name?: string;
  connections: Array<Pick<Connection, "id" | "name">>;
}> = ({ connections, name = "Connections" }) => (
  <Attribute name={name}>
    {connections.length > 0 ? (
      <Badges>
        {connections.map((connection) => (
          <ConnectionBadgeLink key={connection.id} connection={connection} />
        ))}
      </Badges>
    ) : (
      <>None</>
    )}
  </Attribute>
);
