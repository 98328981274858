import { type FC } from "react";

import { type MailboxFragment, useMailboxDestroyMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const MailboxDestroyButton: FC<{
  mailbox: MailboxFragment;
  onDestroy?(): void;
}> = ({ mailbox, onDestroy }) => {
  const [execute, { loading }] = useMailboxDestroyMutation({
    variables: { id: mailbox.id },
  });

  const workflow = mailbox.workflow;

  const destroy = async () => {
    await execute();
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      disabled={mailbox.deleted}
      destroy={destroy}
      loading={loading}
      confirmation={
        <>
          Are you sure you want to delete the mailbox "{mailbox.description}" on
          the workflow "{workflow.name}"? Once deleted any future requests to
          the mailbox will result in an error.
        </>
      }
    />
  );
};
