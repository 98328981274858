import { DASHBOARD_ARTIFACTS_FIELD_LOADER } from "./dashboard_artifacts_field_loader";
import { DASHBOARD_CONNECTIONS_FIELD_LOADER } from "./dashboard_connections_field_loader";
import { DASHBOARD_SECRETS_FIELD_LOADER } from "./dashboard_secrets_field_loader";
import { DASHBOARD_SKILLS_FIELD_LOADER } from "./dashboard_skills_field_loader";
import { DASHBOARD_VAULTS_FIELD_LOADER } from "./dashboard_vaults_field_loader";

export const DASHBOARD_AGENTS_FORM_LOADER = async () => {
  const LOADERS = [
    DASHBOARD_ARTIFACTS_FIELD_LOADER,
    DASHBOARD_CONNECTIONS_FIELD_LOADER,
    DASHBOARD_SECRETS_FIELD_LOADER,
    DASHBOARD_SKILLS_FIELD_LOADER,
    DASHBOARD_VAULTS_FIELD_LOADER,
  ];

  await Promise.all(LOADERS.map((loader) => loader()));
};
