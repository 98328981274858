import {
  DashboardArtifactsListDocument,
  type DashboardArtifactsListQuery,
  type DashboardArtifactsListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_ARTIFACTS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardArtifactsListQuery,
    DashboardArtifactsListQueryVariables
  >({
    query: DashboardArtifactsListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
