import { type FC } from "react";

import { WebhookFragment, useWebhookDestoryMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const WebhookDestroyButton: FC<{
  webhook: WebhookFragment;
  onDestroy?(): void;
}> = ({ webhook, onDestroy }) => {
  const [execute, { loading }] = useWebhookDestoryMutation({
    variables: { id: webhook.id },
  });

  const workflow = webhook.workflow;

  const destroy = async () => {
    await execute();
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      disabled={webhook.deleted}
      destroy={destroy}
      loading={loading}
      confirmation={
        <>
          Are you sure you want to delete the webhook "{webhook.description}" on
          the workflow "{workflow.name}"? Once deleted any future requests to
          the webhook will result in an authorized response. This won't delete
          the workflow itself or any runs.
        </>
      }
    />
  );
};
