import { type FC } from "react";

import { type Vault } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badges } from "@styled/badges";

import { VaultBadgeLink } from "./vault_badge_link";

export const VaultsAttribute: FC<{
  name?: string;
  vaults: Array<Pick<Vault, "id" | "name">>;
}> = ({ vaults, name = "Vaults" }) => (
  <Attribute name={name}>
    {vaults.length > 0 ? (
      <Badges>
        {vaults.map((vault) => (
          <VaultBadgeLink key={vault.id} vault={vault} />
        ))}
      </Badges>
    ) : (
      <>None</>
    )}
  </Attribute>
);
