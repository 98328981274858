import { type FC } from "react";

import { type MailboxFragment } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Attributes } from "@styled/attributes";

import { WorkflowBasicLink } from "./workflow_basic_link";

export const MailboxListAttributes: FC<{
  mailbox: MailboxFragment;
}> = ({ mailbox }) => (
  <Attributes>
    <Attribute name="Address">
      <a href={`mailto:${mailbox.address}`}>{mailbox.address}</a>
    </Attribute>
    <Attribute name="Workflow">
      <WorkflowBasicLink workflow={mailbox.workflow} />
    </Attribute>
  </Attributes>
);
