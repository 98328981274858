import { type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDashboardArtifactsDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Page } from "@styled/page";

import { ArtifactConversationLink } from "./artifact_conversation_link";
import { ArtifactDownloadLink } from "./artifact_download_link";
import { ArtifactRunLink } from "./artifact_run_link";
import { DashboardArtifactsData } from "./artifacts_data";
import { ArtifactDestroyButton } from "./artifacts_destroy_button";

export const DashboardArtifactsDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("undefined ID");
  const { data } = useDashboardArtifactsDetailsQuery({
    variables: { id },
  });

  if (!data) return null;
  const artifact = data.artifact;

  return (
    <Page>
      <Headline title={artifact.name}>
        <ModifyLink to={`/dashboard/artifacts/${artifact.id}/edit`} />
        <ArtifactDownloadLink artifact={artifact} />
        <ArtifactDestroyButton
          artifact={artifact}
          onDestroy={() => {
            navigate("/dashboard/artifacts");
          }}
        />
      </Headline>

      <Attribute name="Name">{artifact.name}</Attribute>
      <Attribute name="Description">{artifact.description}</Attribute>
      <Attribute name="File">{artifact.file?.filename ?? "N/A"}</Attribute>
      <Attribute name="URL">{artifact.url ?? "N/A"}</Attribute>

      <Attribute name="Conversation">
        <ArtifactConversationLink artifact={artifact} />
      </Attribute>

      <Attribute name="Run">
        <ArtifactRunLink artifact={artifact} />
      </Attribute>

      <Attribute name="Data">
        <DashboardArtifactsData id={id} />
      </Attribute>
    </Page>
  );
};
