import { type LoaderFunctionArgs } from "react-router-dom";

import { DASHBOARD_AGENTS_DETAILS_LOADER } from "./dashboard_agents_details_loader";
import { DASHBOARD_AGENTS_FORM_LOADER } from "./dashboard_agents_form_loader";

export const DASHBOARD_AGENTS_EDIT_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const loader = DASHBOARD_AGENTS_DETAILS_LOADER(props);
  await DASHBOARD_AGENTS_FORM_LOADER();
  return await loader;
};
