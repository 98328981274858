import { type FC } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import {
  type ArtifactInput,
  useArtifactChangeMutation,
  useDashboardArtifactsDetailsQuery,
} from "@app_schema";

import { DashboardArtifactsForm } from "./artifacts_form";

export const DashboardArtifactsEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data: defaults } = useDashboardArtifactsDetailsQuery({
    variables: { id: id! },
  });
  const [execute, { loading, data }] = useArtifactChangeMutation();
  const errors = data?.artifact.change.errors;

  const save = async (input: ArtifactInput) => {
    const result = await execute({ variables: { id: id!, input } });
    const artifact = result.data?.artifact.change.artifact;
    if (artifact) navigate(`/dashboard/artifacts/${artifact.id}`);
  };

  if (!defaults) return;
  const artifact = defaults.artifact;

  return (
    <DashboardArtifactsForm
      save={save}
      errors={errors}
      loading={loading}
      artifact={artifact}
    />
  );
};
