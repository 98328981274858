import { type FC } from "react";
import { useNavigate, useParams } from "react-router";

import {
  ScheduleInput,
  useDashboardSchedulesDetailsQuery,
  useScheduleChangeMutation,
} from "@app_schema";

import { DashboardSchedulesForm } from "./schedules_form";

export const DashboardSchedulesEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("undefined ID");

  const navigate = useNavigate();

  const { data: defaults } = useDashboardSchedulesDetailsQuery({
    variables: { id: id },
  });

  const [execute, { loading, data }] = useScheduleChangeMutation();
  const errors = data?.schedule.change.errors;

  const save = async (input: ScheduleInput) => {
    const result = await execute({ variables: { id, input } });
    const schedule = result.data?.schedule.change?.schedule;
    if (schedule) navigate(`/dashboard/triggers/schedules/${schedule.id}`);
  };

  if (!defaults) return;

  return (
    <DashboardSchedulesForm
      schedule={defaults.schedule}
      errors={errors}
      loading={loading}
      save={save}
    />
  );
};
