import { type FC } from "react";

import { type Workflow } from "@app_schema";

import { BasicLink } from "@styled/basic_link";

export const WorkflowBasicLink: FC<{
  workflow: Pick<Workflow, "id" | "name">;
}> = ({ workflow }) => (
  <BasicLink to={`/dashboard/workflows/${workflow.id}`}>
    {workflow.name}
  </BasicLink>
);
